import React from 'react'
import { useUpdateBookingRequestRoute } from './use-update-booking-request-route'
import { UpdateBookingRequestForm } from '../../update'
import { UpdateBookingRequestLoading } from './loading'
import { UpdateBookingRequestCanceled } from './canceled'
import {
  BidStatus,
  BookingRequestStatus,
} from '@beatgig/api-services/booking-request'
import { UpdateBookingRequestFormHeader } from './form-header'
import { UpdateBookingRequestCannotEdit } from './cannot-edit'
import { withoutTypename } from '@beatgig/gql/types/without-typename'

export function SmartUpdateBookingRequestRoute() {
  const {
    onSubmitUpdateBookingRequest,
    onPressCancelBookingRequest,
    cancelBookingRequest,
    goBack,
    updateBookingRequest,
    bookingRequest,
    bookingRequestId,
    amIAdmin,
  } = useUpdateBookingRequestRoute()

  if (bookingRequest.data?.status === BookingRequestStatus.CANCELLED) {
    return <UpdateBookingRequestCanceled onRequestClose={goBack} />
  }

  if (!bookingRequest.data) {
    return (
      <UpdateBookingRequestLoading
        onRequestClose={goBack}
        error={bookingRequest.error}
      />
    )
  }

  if (
    // technically, these 2 are one in the same, but it's there for types
    // once moved to a new screen, this shouldn't really be a problem I think||
    !bookingRequest.data.canIEdit &&
    !amIAdmin
  ) {
    // TODO make this a screen that says you can no longer edit
    return (
      <UpdateBookingRequestCannotEdit
        onRequestClose={goBack}
        bookingRequestId={bookingRequestId}
      />
    )
  }

  const areThereActiveBids = bookingRequest.data.bids.some(
    (bid) => bid.status == BidStatus.PENDING
  )

  const canEditBlockedFields = !areThereActiveBids || amIAdmin

  const {
    adminInstructions,
    artistInstructions,
    bandConfigurationOptions,
    billing,
    start_time,
    genres,
    budget,
    indoorsOrOutdoors,
    performance_length_mins,
    publicEventDescription,
    venue,
  } = bookingRequest.data

  return (
    <UpdateBookingRequestForm
      canIEditPerformanceLength={canEditBlockedFields}
      canIEditStartTime={canEditBlockedFields}
      formHeader={
        <UpdateBookingRequestFormHeader
          bookingRequest={bookingRequest.data}
          venueName={venue.name}
        />
      }
      initialBookingRequest={{
        adminInstructions,
        artistInstructions,
        bandConfigurationOptions,
        billing,
        genres,
        startTime: start_time,
        budget: withoutTypename(budget),
        indoorsOrOutdoors,
        performanceLengthMins: performance_length_mins,
        publicEventDescription,
      }}
      venueLocation={venue.location}
      venueName={venue.name}
      useMultipleDates={false}
      cancelBookingRequestError={cancelBookingRequest.error}
      isCancelingBookingRequest={cancelBookingRequest.loading}
      onCancelBookingRequest={onPressCancelBookingRequest}
      onUpdate={onSubmitUpdateBookingRequest}
      animated={false}
      onClose={goBack}
      networkError={updateBookingRequest.error}
    />
  )
}
