import { ReactNode, ElementType, isValidElement, Children } from 'react'

export const pickChild = (
  children: ReactNode | undefined,
  targetChild: ElementType
) => {
  const target: ReactNode[] = []
  const withoutTargetChildren = Children.map(children, (item) => {
    if (!isValidElement(item)) return item
    if (item.type === targetChild) {
      target.push(item)
      return null
    }
    return item
  })

  const targetChildren = target.length >= 0 ? target : undefined

  return [withoutTargetChildren, targetChildren] as const
}
