import { useRouting } from 'expo-next-react-navigation'
import { AuthedRoutes } from '../routes/authed-routes'
import { useCallback } from 'react'

const useNavigateToBooking = () => {
  const { navigate } = useRouting()

  return {
    navigate: useCallback(
      ({ id }: { id: string }) => {
        navigate(AuthedRoutes.booking(id))
      },
      [navigate]
    ),
  }
}

export default useNavigateToBooking
