import useAuth from '@beatgig/auth-hooks/use-auth'
import { useQuery } from 'react-query'
import { graphql } from '@beatgig/gql/headless'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'

export function useTypesenseArtistApiKey() {
  const auth = useAuth()
  const user = useMyAccount().data

  const { data, refetch } = useQuery(
    ['typesenseArtistApiKey', auth.user?.uid, User.isApproved(user)],
    () => {
      return graphql.TypesenseArtistApiKey()
    },
    {
      staleTime: Infinity,
    }
  )

  return {
    data: data?.artistSearchKey,
    refetch,
  }
}
