import useServerFormError from '@beatgig/forms/hooks/use-server-form-error'
import { UpdateBookingRequestProps } from './../../update/types'
import useToast from '@beatgig/design/hooks/use-toast'
import useAreYouSure from '@beatgig/hooks/use-are-you-sure'
import { useBookingRequest } from '@beatgig/api-hooks/booking-request/get-display'
import { UpdateBookingRequestRoute } from '@beatgig/navigation/stacks/calendar-stack/types'
import { useRoute, useNavigation } from '@react-navigation/native'
import { useCallback } from 'react'
import { useCancelBookingRequest } from '@beatgig/api-hooks/booking-request/cancel'
import { useUpdateBookingRequest } from '@beatgig/api-hooks/booking-request/update'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'

export function useUpdateBookingRequestRoute() {
  const { goBack } = useNavigation()
  const {
    params: { userId, bookingRequestId },
  } = useRoute<UpdateBookingRequestRoute>()

  const user = useMyAccount()

  const amIAdmin = User.isAdmin(user.data)

  const bookingRequest = useBookingRequest({
    bookingRequestId,
  })

  const { execute: executeUpdateBookingRequest, ...updateBookingRequest } =
    useUpdateBookingRequest({
      userId,
    })

  const { execute: executeCancelBookingRequest, ...cancelBookingRequest } =
    useCancelBookingRequest({
      userId,
    })

  const { handleServerFormError } = useServerFormError()

  const onSubmitUpdateBookingRequest = useCallback<
    UpdateBookingRequestProps['onUpdate']
  >(
    async (patch, formik, scrollableProps) => {
      const { data, error } = await executeUpdateBookingRequest({
        ids: bookingRequestId,
        patch,
      })
      if (!error && data?.updateBookingRequests) {
        return {
          didWork: true,
        }
      }
      if (error) {
        handleServerFormError({
          error,
          ...formik,
          ...scrollableProps,
          formName: 'Update Booking Request',
        })
      }
      return {
        didWork: false,
      }
    },
    [bookingRequestId, executeUpdateBookingRequest, handleServerFormError]
  )

  const { toast } = useToast()

  const areYouSureYouWantToCancel = useAreYouSure({
    action: 'cancel this artist recommendation request',
    cancelText: 'Never mind, go back',
  })

  const onPressCancelBookingRequest = useCallback(async () => {
    const confirmCancelBookingRequest = async () => {
      const { data, error } = await executeCancelBookingRequest({
        ids: [bookingRequestId],
      })
      if (data && !error) {
        goBack()
        toast('Your recommended booking was successfully canceled.')
      }
    }
    areYouSureYouWantToCancel(confirmCancelBookingRequest)
  }, [
    areYouSureYouWantToCancel,
    bookingRequestId,
    executeCancelBookingRequest,
    goBack,
    toast,
  ])

  return {
    goBack,
    bookingRequest,
    cancelBookingRequest,
    onPressCancelBookingRequest,
    onSubmitUpdateBookingRequest,
    updateBookingRequest,
    userId,
    bookingRequestId,
    amIAdmin,
  }
}
