import { useCancelBookingRequestsMutation } from '@beatgig/gql'
import { useCallback } from 'react'
import { adminOnBehalfOfContext } from '@beatgig/gql/admin-on-behalf-of'

export function useCancelBookingRequest(options: { userId: string }) {
  const [{ data, error, fetching }, execute] =
    useCancelBookingRequestsMutation()

  return {
    result: data?.cancelBookingRequests[0],
    error,
    loading: fetching,
    execute: useCallback<typeof execute>(
      (props) => execute(props, adminOnBehalfOfContext(options.userId)),
      [execute, options.userId]
    ),
  }
}
