import WebModal from '@beatgig/components/web-modal'
import React from 'react'
import LoadingScreen from '@beatgig/components/loading-screen'
import type { ServerError } from '@beatgig/api-services'

type Props = {
  error?: ServerError
  onRequestClose: () => void
}

export function UpdateBookingRequestLoading({ error, onRequestClose }: Props) {
  return (
    <WebModal animated={false} header onRequestClose={onRequestClose}>
      <LoadingScreen error={error}>Loading booking request...</LoadingScreen>
    </WebModal>
  )
}
