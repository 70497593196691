import React, { useState } from 'react'
import { DateTimePickerProps } from './types'
import moment from 'moment'
import Press from '../press'
import Picker from './picker'

export default function DateTimePicker(props: DateTimePickerProps) {
  const { date, mode = 'date', onCancel, onConfirm, ...picker } = props

  let text = ''
  if (date) {
    if (mode === 'date') {
      text = moment(date).calendar()
    } else if (mode === 'time') {
      text = moment(date).format('hh:mm A')
    }
  }

  const [isVisible, setIsVisible] = useState(false)

  return (
    <Press onPress={() => setIsVisible(true)}>
      {/* <Input
        value={text}
        editable={false}
        sx={{ cursor: 'pointer' }}
        // onClick={() => setIsVisible(true)}
        pointerEvents="none"
      /> */}
      <Picker
        isVisible={isVisible}
        mode={mode}
        date={date}
        onConfirm={(date) => {
          onConfirm(date)
          setIsVisible(false)
        }}
        onCancel={(date) => {
          if (date) {
            onCancel(date)
          }
          setIsVisible(false)
        }}
        {...picker}
      />
    </Press>
  )
}
