import { TypesenseCollectionName } from '@beatgig/gql'
import { ArtistTypesenseSnake } from '@beatgig/gql/types/artist-typesense'
import { useTypesenseSearchFacetValue } from '../hooks/use-search-facet-value'
import { useTypesenseArtistApiKey } from '../typesense-artist-api-key/use-typesense-api-key'

export function useArtistSubgenresTypesense(
  query: string | null,
  { limit }: { limit?: number } = {}
) {
  const apiKey = useTypesenseArtistApiKey().data || null
  const subgenresQuery = useTypesenseSearchFacetValue<ArtistTypesenseSnake>(
    query,
    {
      index: TypesenseCollectionName.ARTISTS,
      apiKey,
      facet: 'subgenres',
      queryBy: 'name',
      facetBy: 'subgenres',
      maxFacetValues: limit,
    }
  )

  return {
    data: subgenresQuery.data,
    isValidating: subgenresQuery.isValidating,
    error: subgenresQuery.error,
  }
}
