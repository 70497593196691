/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@theme-ui/core'
import { DateTimePickerProps } from './types'
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  DateTimePicker,
} from '@material-ui/pickers'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import DateFnsUtils from '@date-io/date-fns'
import useTheme from '@beatgig/theme/use-theme'

export default function Picker(props: DateTimePickerProps) {
  const {
    date,
    onConfirm,
    mode = 'date',
    error = false,
    minimumDate: minDate,
    onCancel,
    ...picker
  } = props

  const { colors, fonts, radii, borderWidths, fontSizes, space } = useTheme()

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colors.primary,
      },
      background: {
        default: colors.primary,
        paper: colors.muted,
      },
      action: {
        active: colors.text,
      },
      text: {
        primary: colors.text,
        secondary: colors.text,
        disabled: colors.text,
        hint: colors.mutedText,
      },
      // grey: {
      //   A700: colors.mutedText,
      // },
      success: {
        main: colors.success,
        contrastText: colors.text,
      },
      common: {
        black: colors.background,
        white: colors.text,
      },
      info: {
        main: colors.mutedText,
        contrastText: colors.primary,
      },
    },
    typography: {
      allVariants: {
        fontFamily: fonts.root,
      },
    },
  })

  const pickerComponent = () => {
    if (mode === 'date') {
      return DatePicker
    } else if (mode === 'time') {
      return TimePicker
    } else if (mode === 'datetime') {
      return DateTimePicker
    }
    throw new Error(
      `[components/date-picker/web] mode prop was "${mode}", must be one of: date, time, datetime.`
    )
  }

  const Component = pickerComponent()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Component
          value={date ?? null}
          onChange={onConfirm}
          defaultValue={null}
          okLabel="Confirm"
          minutesStep={5}
          inputProps={{
            style: {
              color: colors?.text,
              borderColor: error
                ? (colors?.error as string)
                : (colors?.muted5 as string),
              fontFamily: fonts.root,
              borderStyle: 'solid',
              borderWidth: borderWidths?.[1],
              borderRadius: radii?.[3],
              outline: 'none',
              fontSize: fontSizes?.[3],
              padding: `${space?.[2] + space?.[1] + 1}px ${space?.[2]}px`,
              backgroundColor: colors?.background,
            },
          }}
          minDate={minDate}
        />
        <style jsx global>{`
          .MuiBackdrop-root {
            background-color: ${colors.text}50!important;
          }
          .MuiPickersTimePickerToolbar-ampmSelection
            .MuiPickersToolbarText-toolbarBtnSelected {
            background-color: ${colors.background};
            color: ${colors.text};
            border-radius: ${radii[3]}px;
            padding: 0 ${space[2]}px;
          }
        `}</style>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
// export {}
