import { BandConfigurationOption } from '@beatgig/api-services'
import type * as gql from '@beatgig/gql'
import { WithoutTypename } from '@beatgig/gql/types/without-typename'

const displayBandConfiguration = (
  bandConfiguration:
    | BandConfigurationOption
    | gql.BandConfigurationOption
    | keyof gql.BandConfigInput
    | keyof typeof displayConfigurations
    | keyof WithoutTypename<
        NonNullable<gql.ArtistQuery['artist']['display_band_configuration']>
      >
) => {
  if (displayConfigurations[bandConfiguration]?.title) {
    return displayConfigurations[bandConfiguration].title
  }

  return bandConfiguration?.replaceAll('_', ' ')
}

const displayConfigurations: Record<
  Extract<
    Omit<
      keyof gql.BandConfigInput,
      keyof Pick<gql.BandConfigInput, 'unspecified'>
    >,
    string
  >,
  { title: string; pricingTitle: string }
> = {
  solo: {
    title: 'Solo',
    pricingTitle: 'Solo Pricing',
  },
  duo: {
    title: 'Duo',
    pricingTitle: 'Duo Pricing',
  },
  trio: {
    title: 'Trio',
    pricingTitle: 'Trio Pricing',
  },
  fourPlus: {
    title: 'Four people (or more)',
    pricingTitle: 'Four (+) Pricing',
  },
}

export default function useDisplayBandConfiguration() {
  return {
    displayBandConfiguration: displayBandConfiguration,
  }
}

useDisplayBandConfiguration.displayBandConfiguration = displayBandConfiguration
