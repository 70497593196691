import React, {
  ComponentType,
  ReactNode,
  useRef,
  useMemo,
  ReactElement,
} from 'react'
import { useRouter } from 'next/router'
import { pickChild } from './pick-child'
import { View, StyleSheet, ViewStyle, Platform } from 'react-native'

type Params = Record<string, string>

export type ModalStackContext<
  Query extends Record<string, Params | null>,
  Name extends keyof Query = keyof Query
> = {
  modals: Name[]
  query: Record<string, string>
  push: (
    name: Name,
    params?: Query[Name] extends any ? Query[Name] : never
  ) => void
}

export type RouteModalChild = (state: {
  /**
   * Boolean indicating whether or not this modal is active.
   */
  active: boolean
  dimensions: {
    height: number
    width: number
  }
}) => ReactNode

type CardProps<Query extends Record<string, Params | null | undefined>> = {
  modal: React.ReactElement<ModalProps<Query>>
  dimensions: {
    width: number
    height: number
  }
  cardStyle?: ViewStyle
  active: boolean
  zIndex: number
}

export type ModalStackProps = {
  children: ReactNode
  screen: ReactElement | ComponentType<any>
  style?: ViewStyle
  cardStyle?: ViewStyle
}

// type ShouldRender<
//   Query extends Record<string, Params | null> = {},
//   Name extends keyof Query = keyof Query,
//   HasQuery = Query[Name] extends null | undefined ? false : true
// > = (stack: {
//   state: {
//     name: Name
//     query: Query[Name]
//     modals: (keyof Query)[]
//   }
//   /**
//    * Whether or not this modal is included in the current state.
//    */
//   matches: boolean
// }) => boolean

type PathAndBooleanProp<
  Query extends Record<string, Params | null | undefined> = {},
  Name extends keyof Query = keyof Query,
  HasQuery = Query[Name] extends null | undefined ? false : true
> = HasQuery extends true
  ?
      | {
          /**
           * A pure function to determine if this route is active.
           *
           * By default, it just checks if the `name` is in the active `modals` query parameter.
           *
           * If this screen has query parameters, then this prop is required to make sure you check that they're there and not empty.
           *
           * In the case that you don't want to validate, just pass `({matches}) => matches`. This will make the screen render as long as it's in the `modals` array, and will ignore the other query params.
           *
           * If this route's types have `null` parameters, then it can't be used.
           *
           */
          shouldRender: (stack: {
            state: {
              name: Name
              query: Query[Name]
              modals: (keyof Query)[]
            }
            /**
             * Whether or not this modal is included in the current state.
             */
            matches: boolean
          }) => boolean
          path?: never
        }
      | {
          /**
           * A URL-based API, for simple usage, instead of the `shouldRender` prop
           *
           * A `path` that, if set, will render the modal when the `asPath` of `next/router` matches.
           *
           * Example:
           *
           * ```tsx
           * <RouteModal
           *   name="images"
           *   path="/artist/[id]/images/[image]"
           * />
           * ```
           *
           * In this case, the modal will render when the URL matches that path.
           *
           */
          path: string
          shouldRender?: never
        }
  : {
      shouldRender?: never
      path?: never
    }

export type ModalProps<
  Query extends Record<string, Params | null | undefined> = {},
  Name extends keyof Query = keyof Query
> = {
  name: Name
  /**
   * Defaults to the parent's `cardStyle` if it exists.
   */
  style?: ViewStyle
} & PathAndBooleanProp<Query, Name> &
  (
    | {
        component: ComponentType<any>
        children?: never
      }
    | {
        /**
         * Alternative to the `component` prop.
         *
         * This lets you base your animation on the `active` state.
         *
         * For instance, you might want an animation using `moti`'s `AnimatePresence`.
         *
         * In the example below, using `moti`, we slide the screen in/out from the right.
         *
         * **Important** make sure that if you use this, you hide the screen when `active` is false.
         *
         * ```tsx
         * <RouteModal name="profile">
         *   {({ active, dimensions }) => {
         *     // Please implement your own solution that wraps this with React.memo
         *     return (
         *       <AnimatePresence>
         *         {active && (
         *           <MotiView
         *             style={{ flex: 1 }}
         *             from={{ translateX: dimensions.width }}
         *             animate={{ translateX: 0 }}
         *             exit={{ translateX: dimensions.width }}
         *           >
         *               <ProfileScreen />
         *           </MotiView>
         *         )}
         *       </AnimatePresence>
         *     )
         *   }}
         * </RouteModal>
         * ```
         */
        children: RouteModalChild
        component?: never
      }
  )

const empty = {
  array: [],
  object: {},
}

export function createModalStack<
  Query extends Record<string, Params | null | undefined> = {}
>() {
  const useModalNavigator = () => {
    const { query = empty.object, pathname } = useRouter()
    let { _modals: modals = empty.array } = query as any as {
      _modals: (keyof Query)[]
    }
    if (typeof modals === 'string') modals = [modals]

    return {
      modals,
      query: query as Query[keyof Query],
      pathname,
    }
  }
  const RouteModal = <RouteName extends keyof Query>(
    _: ModalProps<Query, RouteName>
  ) => {
    return <></>
  }
  const Card = ({
    modal,
    cardStyle,
    dimensions,
    active,
    zIndex,
  }: CardProps<Query>) => {
    const { name, style: modalStyle = cardStyle } = modal.props
    let children: ReactNode | null = null

    const Screen = useMemo(() => {
      const Component = modal.props.component
      return Component && <Component />
    }, [modal.props.component])

    const childrenState = useMemo<Parameters<RouteModalChild>[0]>(
      () => ({
        active,
        dimensions,
      }),
      [active, dimensions]
    )

    if (modal.props.children) {
      children = modal.props.children(childrenState)
    } else if (active && Screen) {
      children = Screen
    }
    return (
      <View
        pointerEvents={active ? 'box-none' : 'none'}
        style={[styles.container, active && modalStyle, active && { zIndex }]}
        key={name as string}
      >
        {children}
      </View>
    )
  }
  const ModalStack = ({
    children,
    screen,
    style,
    cardStyle,
  }: ModalStackProps) => {
    const { modals, query } = useModalNavigator()
    const [, routeModals] = pickChild(children, RouteModal)

    const Screen = useMemo(() => {
      if (React.isValidElement(screen)) {
        return screen
      }
      const Screen = screen
      return <Screen />
    }, [screen])

    const dimensions = useRef({
      height: 0,
      width: 0,
    })

    const onLayout = useRef<
      NonNullable<React.ComponentProps<typeof View>['onLayout']>
    >(({ nativeEvent }) => {
      dimensions.current = nativeEvent.layout
    }).current

    const stackStyle = useMemo(
      () => (style ? [styles.container, style] : styles.container),
      [style]
    )

    const registeredScreens: Partial<Record<keyof Query, boolean>> = {}

    return (
      <View onLayout={onLayout} style={stackStyle}>
        {Screen}
        {/* TODO move this into context */}
        {Platform.OS === 'web' &&
          React.Children.map(
            routeModals,
            (modal: React.ReactElement<ModalProps<Query>>, modalIndex) => {
              const { name, shouldRender, path } = modal.props
              if (registeredScreens[name]) {
                console.error(
                  `[expo-next-react-navigation] Duplicate screen name detected: ${
                    name as string
                  } in the same stack. This is not allowed.`
                )
              }
              registeredScreens[name] = true

              let active = modals.includes(name)

              if (path) {
                console.warn(
                  '[expo-next-react-navigation] path prop is not implemented yet.'
                )
              }
              if (shouldRender) {
                active = shouldRender({
                  state: {
                    name,
                    modals,
                    query,
                  },
                  matches: modals.includes(name),
                })
                if (typeof active !== 'boolean') {
                  console.error(
                    '[expo-next-react-navigation] shouldRender must return a boolean. However, for screen ' +
                      (name as string) +
                      ' it returned: ',
                    active
                  )
                }
              }
              let zIndex = -1
              if (modals.indexOf(name) !== -1) {
                zIndex = 1 + modals.indexOf(name)
              } else if (active) {
                // is there a better way...
                // zIndex = modalIndex
                zIndex = 1
              }
              return (
                <Card
                  zIndex={zIndex}
                  cardStyle={cardStyle}
                  modal={modal}
                  active={active}
                  dimensions={dimensions.current}
                  key={name as string}
                />
              )
            }
          )}
      </View>
    )
  }

  return {
    ModalStack,
    RouteModal,
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    ...StyleSheet.absoluteFillObject,
  },
})
