import WebModal from '@beatgig/components/web-modal'
import React from 'react'
import FormRow from '@beatgig/forms/components/row'
import { Text } from 'dripsy'
import LiveChatUsNote from '@beatgig/ui/customer-service/live-chat-us-note'

type Props = {
  onRequestClose: () => void
  bookingRequestId: string
}

export function UpdateBookingRequestCannotEdit({
  onRequestClose,
  bookingRequestId,
}: Props) {
  return (
    <WebModal animated={false} header onRequestClose={onRequestClose}>
      <FormRow>
        <Text sx={{ mb: 3 }}>
          This booking request cannot be edited at this time. This may be
          because it already has bids.
        </Text>
        <LiveChatUsNote
          prepopulatedMessage={`I'd like help editing my booking request. The ID is #${bookingRequestId}`}
        />
      </FormRow>
    </WebModal>
  )
}
