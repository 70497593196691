import { BookingRequestDisplay } from '@beatgig/api-services/booking-request'
import React from 'react'
import Note from '@beatgig/design/components/note'
import { BookingRequestConfirmationNote } from '../../confirmation-note'
import { BookingRequestBuyerExplanation } from '@beatgig/ui/booking-request-buyer-explanation'
import { View } from 'dripsy'

type Props = {
  bookingRequest: BookingRequestDisplay
  venueName: string
}

export function UpdateBookingRequestFormHeader({
  bookingRequest,
  venueName,
}: Props) {
  return (
    <View sx={{ mb: 3 }}>
      <BookingRequestConfirmationNote bookingRequest={bookingRequest} />
      {bookingRequest.buyer_frontend_copy?.notes?.map((note, i) => (
        <Note sx={{ mb: 3 }} key={i} {...note} filling="filled" />
      ))}
      <BookingRequestBuyerExplanation venueName={venueName} />
    </View>
  )
}
