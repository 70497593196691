import useActionSheet from '@beatgig/hooks/use-action-sheet'

export default function useRequestCloseForm() {
  const { showActionSheetWithOptions } = useActionSheet()

  const requestClose = (isDirty: boolean, onClose: () => void) => () => {
    if (isDirty) {
      const options = ['Continue Editing', 'Discard Changes']

      const cancelButtonIndex = 0
      const destructiveButtonIndex = 1
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: 0,
          destructiveButtonIndex: 1,
          title: 'You have unsaved changes.',
          message: 'Do you want to discard changes, or continue editing?',
        },
        (index) => {
          if (index === cancelButtonIndex) {
          } else if (index === destructiveButtonIndex) {
            onClose()
          }
        }
      )
    } else {
      onClose()
    }
  }

  return {
    requestClose,
  }
}
