import React from 'react'
import { View, useSx, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import { StyleSheet } from 'react-native'
import Animated, {
  useAnimatedStyle,
  withTiming,
  withDelay,
  useSharedValue,
} from 'react-native-reanimated'

type Props = {
  fieldNameEnglish: 'Start time' | 'performance length'
  isFieldEditable: boolean
}

export function DisableEditField({ fieldNameEnglish, isFieldEditable }: Props) {
  const sx = useSx()
  const focused = useSharedValue(false)
  const animatedStyle = useAnimatedStyle(() => {
    let delay = 0
    if (!focused.value) delay = 2000
    return {
      opacity: withDelay(delay, withTiming(focused.value ? 1 : 0)),
    }
  })
  const toggleFocusState = (next: boolean) => () => (focused.value = next)
  if (isFieldEditable) {
    return null
  }

  return (
    <Press
      onPressIn={toggleFocusState(true)}
      onPressOut={toggleFocusState(false)}
      onHoverIn={toggleFocusState(true)}
      onHoverOut={toggleFocusState(false)}
      style={StyleSheet.absoluteFillObject}
      sx={{ cursor: 'not-allowed' }}
    >
      <Animated.View style={[sx({ flex: 1 }), animatedStyle]}>
        <View
          style={StyleSheet.absoluteFillObject}
          sx={{
            bg: 'background',
            opacity: 0.6,
          }}
        />
        <View
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            p: 3,
            flex: 1,
          }}
        >
          <View sx={{ p: 3, bg: 'text', maxWidth: 300, borderRadius: 3 }}>
            <Text sx={{ textAlign: 'center', color: 'background' }}>
              {fieldNameEnglish} is no longer editable since there are active
              bids.
            </Text>
          </View>
        </View>
      </Animated.View>
      {/* <MotiView
        transition={transition}
        delay={!focused ? 2000 : 0}
        state={animationState}
        style={sx({ flex: 1 })}
      >
        
      </MotiView> */}
    </Press>
  )
}
