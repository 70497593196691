import useSWR from 'swr'
import { createTypesenseClient } from './typesense'

type Options<T> = {
  facet: Extract<keyof T, string> | (string & {})
  index: string
  apiKey: string | null
  queryBy: Extract<keyof T, string> | Array<keyof T>
  facetBy: Extract<keyof T, string> | Array<keyof T>
  maxFacetValues?: number
}

export type AlgoliaHit<T> = T & {
  _highlightResult?: HighlightResult<T>
  objectID: string
}

type HighlightResult<T> = {
  [key in keyof T]?: {
    value?: string
    matchLevel?: 'none' | 'full'
    matchedWords?: string[]
    fullyHighligted?: boolean
  }
}

function useTypesenseSearchFacetValue<T extends object>(
  queryText: string | null,
  { facet, index, apiKey, queryBy, facetBy, maxFacetValues = 30 }: Options<T>
) {
  const query = queryText // TODO debounce this?

  const response = useSWR(
    () =>
      query !== null && apiKey
        ? [
            facet,
            query,
            index,
            queryBy,
            facetBy,
            maxFacetValues,
            'typesense-facet-search',
          ]
        : null,
    async () => {
      const results = await createTypesenseClient(apiKey as string)
        .collections(index)
        .documents()
        .search(
          {
            facet_query: `${facet}:${query}`,
            q: '',
            query_by: Array.isArray(queryBy) ? queryBy.join(',') : queryBy,
            per_page: 0,
            max_facet_values: maxFacetValues,
            facet_by: Array.isArray(facetBy) ? facetBy.join(',') : facetBy,
          },
          {}
        )

      return results
    },
    {
      refreshInterval: 0,
      refreshWhenHidden: false,
    }
  )

  return response
}

export { useTypesenseSearchFacetValue }
