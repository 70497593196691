import { useUpdateBookingRequestMutation } from '@beatgig/gql'
import { useCallback } from 'react'
import { adminOnBehalfOfContext } from '@beatgig/gql/admin-on-behalf-of'

export function useUpdateBookingRequest({ userId }: { userId: string }) {
  const [{ data, error, fetching }, execute] = useUpdateBookingRequestMutation()

  return {
    loading: fetching,
    error,
    data,
    execute: useCallback<typeof execute>(
      (props) => execute(props, adminOnBehalfOfContext(userId)),
      [execute, userId]
    ),
  }
}
