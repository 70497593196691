import { BookingRequestDisplay } from '@beatgig/api-services/booking-request'
import Note from '@beatgig/design/components/note'
import Button from '@beatgig/components/button'
import useNavigateToBooking from '@beatgig/navigation/hooks/use-navigate-to-booking'

type Props = {
  bookingRequest: Pick<BookingRequestDisplay, 'booking'>
}

export function BookingRequestConfirmationNote({ bookingRequest }: Props) {
  const { navigate: openBooking } = useNavigateToBooking()

  if (bookingRequest.booking?.id) {
    return (
      <Note
        variant="blue"
        filling="filled"
        actions={
          <Button
            onPress={() => {
              if (bookingRequest.booking) {
                openBooking({ id: bookingRequest.booking.id })
              }
            }}
            iconLeft={{
              name: 'open-outline',
            }}
            sx={{ bg: 'text', borderColor: 'text' }}
          >
            View Booking
          </Button>
        }
        sx={{ mb: 3 }}
      >
        Your booking is confirmed.
      </Note>
    )
  } else {
    return null
  }
}
