import React from 'react'
import { View, H5 } from 'dripsy'
import Card from '@beatgig/design/components/card'
import { APP_NAME } from '@beatgig/constants'
import SeeMoreText from '@beatgig/design/components/show-more-text'

type Props = {
  venueName: string
}

export function BookingRequestBuyerExplanation(props: Props) {
  const { venueName } = props

  const title = `How it works`
  const message = `Fill out the form below, and based on your preferences, ${APP_NAME} will recommend the best artists to book ${
    venueName ? `at ${venueName}` : ''
  }. `

  const message2 = `Each artist will then bid on your event with their price to perform.

Once artists have submitted their bids, your ${APP_NAME} support representative will notify you that it's time to pick an artist.

Once you press "Create", you can track the status of your booking request on your ${APP_NAME} calendar.`

  const textAlign = 'left'

  return (
    <Card>
      <Card.Content>
        <View sx={{ flexDirection: [null, 'row'] }}>
          <View sx={{ flex: 1, maxWidth: 500 }}>
            <H5 sx={{ textAlign }}>{title}</H5>
            <SeeMoreText
              showDots={false}
              showMoreColor="primary"
              textSx={{
                color: 'mutedText',
                fontSize: 3,
                lineHeight: 4,
                textAlign,
              }}
              text={message}
              long={message + '\n\n' + message2}
            />
          </View>
        </View>
      </Card.Content>
      {/* <Card.Footer bg="muted">
        <Text sx={{ color: 'text', textAlign }}>{actionText}</Text>
      </Card.Footer> */}
    </Card>
  )
}
