import React, { createContext, useContext, useCallback } from 'react'
import { LayoutChangeEvent } from 'react-native'
import { View } from 'dripsy'

export const ContainerQueryContext = createContext(0)
export const useContainerWidth = () => useContext(ContainerQueryContext)

function useWidth() {
  const [width, setWidth] = React.useState(0)
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  const onLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    if (mounted.current) {
      setWidth(nativeEvent.layout.width)
    }
  }, [])

  return {
    width,
    onLayout,
  }
}

export const ContainerQueryWhenWidth = ({
  children,
  fallback = null,
}: {
  children: React.ReactNode
  fallback?: React.ReactNode
}) => {
  const width = useContainerWidth()
  if (!width) return <>{fallback}</>

  return <>{children}</>
}

export const ContainerQueryProvider = ({
  children,
  forceWidth,
}: // containerSx,
{
  children: React.ReactNode
  forceWidth?: number
  // containerSx?: React.ComponentProps<typeof View>['sx']
}) => {
  const { width, onLayout } = useWidth()

  return (
    <ContainerQueryContext.Provider value={forceWidth ?? width}>
      <View
        onLayout={forceWidth != null ? undefined : onLayout}
        sx={{ flex: 1 }}
      >
        {children}
      </View>
    </ContainerQueryContext.Provider>
  )
}
