import React, { useMemo, useState, useRef, useCallback } from 'react'
import PaperDatePicker, {
  ModalRef,
} from '@beatgig/components/date-time-picker/paper-picker'
import Grid from '@beatgig/design/components/grid'
import { useMultiDateTimeFieldContext } from './context'
import { DateTime } from 'luxon'
import { DateObject } from '../date-time-container/context'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import DateTimePicker from '@beatgig/components/date-time-picker/_index'
import FieldLabel from '../field-label'
import { View, Text } from 'dripsy'
import format from 'date-fns/format'
import Timezone from '../../components/timezone'
import Ionicons from '@beatgig/design/components/ionicons'
import Press from '@beatgig/components/press'
import { useFieldFast } from '../../hooks/use-fast-field'
import { useFormikContext } from 'formik'
import Button from '@beatgig/components/button'

type Props = {
  timeTooltip?: React.ReactNode
  timeLabel?: string
  datesLabel?: string
  required?: boolean
  datesTooltip?: React.ReactNode
  validRange?: React.ComponentProps<typeof PaperDatePicker>['validRange']
  timezone: string
  maxDates?: number
}

export default function MultiDateTimePicker({
  timeTooltip,
  timeLabel,
  datesLabel,
  required = true,
  datesTooltip,
  validRange,
  timezone,
  maxDates,
}: Props) {
  const {
    onChangeTime,
    onChangeDates,
    dateObjects,
    timeObject,
    name,
  } = useMultiDateTimeFieldContext()
  const { submitCount } = useFormikContext()

  const [, { error, touched }] = useFieldFast<Date | null | string>(
    name as string
  )
  const hasErrored = !!error && !!(touched || submitCount)

  const modalRef = useRef<ModalRef>(null)

  const openModal = () => modalRef.current?.open?.()

  const handleChangeTime = useCallback(
    (date: Date | null) => {
      if (date) {
        const { hour, minute, second } = DateTime.fromJSDate(date)
        onChangeTime({ hour, minute, second })
      }
    },
    [onChangeTime]
  )

  const displayTime = useMemo(() => {
    return DateTime.fromObject(timeObject).toJSDate()
  }, [timeObject])

  const displayDates = useMemo(() => {
    return dateObjects.filter(Boolean).map((dateObject) => {
      // this is just for displaying, so no timezone necessary!
      return DateTime.fromObject({ ...dateObject, ...timeObject }).toJSDate()
    })
  }, [dateObjects, timeObject])

  return useMemo(
    () => (
      <Grid columns={[1, 2]}>
        <Grid.Item>
          {!!datesLabel && (
            <FieldLabel hasErrored={hasErrored} required={required}>
              {datesLabel}
            </FieldLabel>
          )}
          <HoverTooltip
            text={datesTooltip}
            bg="text"
            placement="top"
            anchor="right"
          >
            <PaperDatePicker
              error={hasErrored}
              mode="multiple"
              dates={displayDates}
              modalRef={modalRef}
              validRange={validRange}
              onChange={({ dates, datePressed, change }) => {
                const certainDates = dates.filter(Boolean)
                // if we just cleared all the dates, keep the last one
                if (!certainDates.length) {
                  if (datePressed) {
                    const { year, day, month } = DateTime.fromJSDate(
                      datePressed
                    )

                    const fallbackDate = { year, day, month }

                    // we need to call this to trigger a re-render
                    // otherwise the calendar will show an empty date
                    onChangeDates({ dates: [fallbackDate] })
                  }
                } else {
                  const nextDates: DateObject[] = []
                  certainDates.forEach((dateOrString) => {
                    let date = dateOrString
                    if (typeof date === 'string') {
                      date = new Date(date)
                    }
                    if (date) {
                      const { year, day, month } = DateTime.fromJSDate(date)
                      nextDates.push({ year, day, month })
                    }
                  })
                  onChangeDates({ dates: nextDates })
                }
              }}
              onConfirm={({ dates }) => {
                const nextDates: DateObject[] = []
                dates.forEach((dateOrString) => {
                  let date = dateOrString
                  if (typeof date === 'string') {
                    date = new Date(date)
                  }
                  if (date) {
                    const { year, day, month } = DateTime.fromJSDate(date)
                    nextDates.push({ year, day, month })
                  }
                })

                console.log('[multi-date-picker] confirmed, do nothing!', {
                  nextDates,
                })

                // NO-OP, just use onChange?
              }}
            />
          </HoverTooltip>
          <DatesList maxDates={maxDates} onOpenModal={openModal} />
        </Grid.Item>
        <Grid.Item>
          {!!timeLabel && (
            <FieldLabel
              //   isHovered={isHovered}
              //   hasErrored={hasErrored}
              required={required}
            >
              {timeLabel}
            </FieldLabel>
          )}
          <HoverTooltip
            text={timeTooltip}
            bg="text"
            placement="top"
            anchor="right"
          >
            <DateTimePicker
              mode={'time'}
              date={displayTime}
              //   onCancel={onSelectDate}
              onCancel={handleChangeTime}
              onConfirm={handleChangeTime}
              headerTextIOS={timeLabel}
              //   error={hasErrored}
            />
            <Timezone sx={{ mt: 2 }} timezoneName={timezone} />
          </HoverTooltip>
        </Grid.Item>
      </Grid>
    ),
    [
      datesLabel,
      datesTooltip,
      displayTime,
      handleChangeTime,
      timezone,
      timeLabel,
      validRange,
      hasErrored,
      maxDates,
      required,
      displayDates,
      onChangeDates,
      timeTooltip,
    ]
  )
}

const limit = 3

function DatesList({
  onOpenModal,
  maxDates: _maxDates = -1,
}: {
  onOpenModal: () => void
  maxDates?: number
}) {
  const [count, setCount] = useState(limit)
  const { dateObjects, onRemoveDate } = useMultiDateTimeFieldContext()

  const maxDates = _maxDates >= 0 ? _maxDates : null
  const hideMoreDatesButton = !!(maxDates && dateObjects.length >= maxDates)

  const hideList = !!(hideMoreDatesButton && dateObjects.length === 1)

  const increment = () => setCount((count) => count + limit)
  const decrement = () => {
    setCount(limit)
    // setCount((count) => {
    //   const next = count - limit

    //   return Math.max(limit, next)
    // })
  }

  if (dateObjects.length < 1) return null

  const { length } = dateObjects
  const extra = length - count

  const canIncrement = extra > 0
  const canDecrement = count > limit

  const hasSelectedMultipleDays = dateObjects.length > 1
  const canRemove = hasSelectedMultipleDays

  return (
    <View>
      {!hideList &&
        dateObjects.slice(0, count).map((dateObject, i) => {
          const displayDate = format(
            DateTime.fromObject({ ...dateObject, hour: 21 }).toJSDate(),
            'eee, MMM do, yyyy'
          )
          const onRemove = () => {
            onRemoveDate(i, dateObject)
          }

          return (
            <View
              sx={{
                // mb: 1,
                mt: !i ? 2 : 0,
                py: 2,
                flexDirection: 'row',
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomColor: 'border',
              }}
              key={JSON.stringify(dateObject)}
            >
              <View sx={{ flex: 1 }}>
                <Text>{displayDate}</Text>
              </View>
              {canRemove && (
                <Press onPress={onRemove}>
                  <Ionicons name="close" />
                </Press>
              )}
            </View>
          )
        })}

      {(canDecrement || canIncrement) && (
        <View
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            py: 2,
            borderBottomWidth: '1',
            borderColor: 'border',
          }}
        >
          {canIncrement && (
            <Text onPress={increment} sx={{ color: 'cyan' }}>
              +{extra} More
            </Text>
          )}
          {canDecrement && (
            <Text onPress={decrement} sx={{ color: 'accent' }}>
              Hide dates
            </Text>
          )}
        </View>
      )}

      {!hideMoreDatesButton && (
        <View sx={{ flexDirection: 'row', alignItems: 'center', mt: 3 }}>
          <Button
            variant="small"
            iconLeft={{ name: 'calendar-outline' }}
            onPress={onOpenModal}
          >
            Add More Dates
          </Button>
        </View>
      )}
    </View>
  )
}
