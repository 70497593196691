import React from 'react'
import { Row } from 'dripsy'
import Sizer from '@beatgig/components/sizer'

type Props = {
  children: React.ReactNode
} & React.ComponentProps<typeof Row>

export default function FormSet(props: Props) {
  const { children, sx = {} } = props

  return (
    <Row sx={{ flexDirection: ['column', 'row'], ...sx }}>
      {React.Children.map(children, (child: React.ReactElement, index) => {
        return (
          <Sizer
            {...{
              mr: index ? 0 : [0, 2],
              ml: index ? [0, 2] : 0,
              mt: index ? [3, 0] : 0,
              flex: [null, 1],
            }}
            // webContainerSx={{ flex: [null, 1] }}
          >
            {React.cloneElement(child)}
          </Sizer>
        )
      })}
    </Row>
  )
}
