import { useBookingRequestQuery } from '@beatgig/gql'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import { useCallback } from 'react'

export function useBookingRequest(
  keyProps: {
    bookingRequestId: string
    userId?: string
  } | null
) {
  const [{ data, error }, revalidate] = useBookingRequestQuery(
    useAdminOnBehalfOf(keyProps?.userId || null, {
      variables: {
        id: keyProps?.bookingRequestId || '',
      },
      pause: !keyProps?.bookingRequestId,
      requestPolicy: 'cache-and-network',
    })
  )

  return {
    data: data?.bookingRequest,
    error,
    revalidate: useCallback(
      () =>
        revalidate({
          requestPolicy: 'network-only',
        }),
      [revalidate]
    ),
  }
}
