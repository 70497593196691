import { UserRole } from '@beatgig/api-services/user'
import { useMyArtistsQuery } from '@beatgig/gql'
import { useCallback } from 'react'
import { useAdminOnBehalfOf } from '@beatgig/gql/admin-on-behalf-of'
import useMyAccount from '@beatgig/auth-hooks/use-my-account'
import { User } from '@beatgig/api/user'

export default function useMyArtists(
  config?:
    | { userId: string; userRole: UserRole }
    | { userId?: never; userRole?: never }
) {
  const me = useMyAccount().data
  const [{ data, error, stale, fetching }, revalidate] = useMyArtistsQuery(
    useAdminOnBehalfOf(config?.userId || null, {
      variables: {},
      pause:
        !User.hasOnboarded(me) ||
        Boolean(config?.userRole && config?.userRole !== UserRole.SELLER),
    })
  )

  return {
    data: data?.myArtists,
    error,
    revalidate: useCallback(
      () => revalidate({ requestPolicy: 'network-only' }),
      [revalidate]
    ),
    stale,
    fetching,
  }
}
