import React from 'react'
import { View, Text, useDripsyTheme } from 'dripsy'
import Ionicons from '@expo/vector-icons/build/Ionicons'
import { DripsyTheme } from '@beatgig/theme'
import { Box } from '../box'

type Props = {
  children?: React.ReactNode
  icon?: {
    size?: number
    name: React.ComponentProps<typeof Ionicons>['name']
    position?: 'left' | 'right'
    color?: keyof DripsyTheme['colors']
  }
  label?: string
  color?: keyof DripsyTheme['colors']
  capitalize?: boolean
} & React.ComponentProps<typeof View>

export default function MetaIcon(props: Props) {
  const {
    sx = {},
    label,
    children = label,
    color = 'mutedText',
    icon,
    capitalize = false,
    accessibilityLabel = label,
    ...rest
  } = props

  const { colors } = useDripsyTheme().theme
  const iconColor =
    (icon?.color && colors?.[icon?.color]) ??
    icon?.color ??
    colors?.[color] ??
    color

  return (
    <View
      {...rest}
      accessibilityLabel={accessibilityLabel}
      sx={{ flexDirection: 'row', alignItems: 'center', ...sx }}
    >
      {!!icon && (
        <Ionicons
          name={icon.name}
          size={icon.size ?? 20}
          color={iconColor as string}
        />
      )}
      <Box flex={1}>
        <Text
          sx={{
            pl: '$2',
            color,
            textTransform: capitalize ? 'capitalize' : undefined,
          }}
        >
          {children}
        </Text>
      </Box>
    </View>
  )
}
