import WebModal from '@beatgig/components/web-modal'
import React from 'react'
import FormRow from '@beatgig/forms/components/row'
import { Text } from 'dripsy'

type Props = {
  onRequestClose: () => void
}

export function UpdateBookingRequestCanceled({ onRequestClose }: Props) {
  return (
    <WebModal animated={false} header onRequestClose={onRequestClose}>
      <FormRow>
        <Text>This booking request was canceled.</Text>
      </FormRow>
    </WebModal>
  )
}
