import React, { useMemo, useCallback, useState } from 'react'
import { View, Row } from 'dripsy'
import AutoComplete from '@beatgig/design/components/auto-complete'
import { useFieldArray } from '../hooks/use-field-array'
import { AutoCompleteOption } from '@beatgig/design/components/auto-complete/types'
import Spacer from '@beatgig/design/components/spacer'
import Badge from '@beatgig/design/components/badge'
import Press from '@beatgig/components/press'
import FieldLabel from './field-label'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import useIsFocused from '@beatgig/hooks/use-is-focused'
import { useFormikContext } from 'formik'
import ErrorText from './error'
import { FieldName } from './types'
import { ScrollToField } from './scroll-to-field'
import { AnimatePresence, MotiView } from 'moti'
import { Layout, ZoomOut } from 'react-native-reanimated'
import { Platform } from 'react-native'

type Props<T extends object, Value = any> = {
  name: FieldName<T>
  options?: string[]
  label?: string
  labelPosition?: 'top' | 'left' | 'right'
  tooltip?: string | React.ReactNode
  required?: boolean
  placeholder?: string
  /**
   * If set, this text will show up when no options match the typed search.
   *
   * For instance, if you set it to `Custom genre` and have typed `rap`, then an empty result will show up as:
   *
   * `Custom genre: "rap"`
   *
   * @default `Custom`.
   *
   * This default is taken from the `AutoComplete.Empty` props.
   */
  emptyOptionText?: string
  filter?: boolean
  /**
   * Custom function that loops through the items provided
   */
  renderItems?: (
    value: Value[],
    options: { remove: (i: number) => void }
  ) => React.ReactNode
} & Omit<
  React.ComponentProps<typeof AutoComplete>,
  'options' | 'keyExtractor' | 'onSelect' | 'filter'
>

const arr = []

export default function AutoCompleteMultiSelectFieldArray<
  T extends object,
  Value extends any = any
>(props: Props<T, Value>) {
  const {
    sx = {},
    name,
    options: _options,
    searching = !_options,
    allowsCustomValue = true,
    label,
    tooltip,
    labelPosition = 'top',
    required,
    emptyOptionText,
    filter = true,
    renderItems: _renderItems,
    value: valueProp,
    onChangeText: onChangeTextProp,
    ...autocompleteProps
  } = props

  const [isFocused, focusedBindings] = useIsFocused()

  const [_textValue, _setTextValue] = useState('')

  const textValue =
    valueProp != null && onChangeTextProp != null ? valueProp : _textValue
  const setTextValue =
    valueProp != null && onChangeTextProp != null
      ? onChangeTextProp
      : _setTextValue

  const validateControlProps = () => {
    if (
      [props.value, props.onChangeText].filter((v) => v != null).length === 1
    ) {
      console.error(
        `[auto-complete-multi-select-field-array] You must provide both a value and onChangeText prop to use the AutoCompleteMultiSelectFieldArray component. Or, pass neither.`
      )
    }
  }

  validateControlProps()

  const [{ value = arr }, { error, touched }, { push, remove }] = useFieldArray<
    string | undefined
  >(name)
  const { submitCount } = useFormikContext()

  const hasErrored = error != undefined && !!(touched || submitCount)

  const options = useMemo(() => {
    return _options?.map((option) => {
      return {
        label: option,
        value: option,
        selected: value?.includes(option),
      } as AutoCompleteOption
    })
    // .filter((option) => !value.includes(option.value))
  }, [_options, value])

  const removeValue = useCallback(
    (itemToRemove: string) => {
      const indexToRemove = value?.findIndex(
        (option) => option === itemToRemove
      )

      if (indexToRemove !== -1) {
        remove(indexToRemove)
      }
      return indexToRemove
    },
    [remove, value]
  )

  const onSelect = useCallback(
    (selected?: string) => {
      console.log('[auto-complete] selected', { selected, value })
      if (!selected) return
      if (!value?.includes(selected)) {
        push(selected)
      } else {
        removeValue(selected)
      }
    },
    [push, value, removeValue]
  )

  const renderItems = useMemo(() => {
    if (typeof _renderItems === 'function') {
      return _renderItems(value as any, { remove })
    }

    const Presence = Platform.select({
      web: AnimatePresence,
      default: React.Fragment,
    })

    const ParentLayout = Platform.select({
      web: React.Fragment,
      default: MotiView as any,
    })

    const parentLayoutProps = Platform.select({
      native: {
        layout: Layout.duration(300),
      },
    })

    return (
      <ParentLayout
        {...parentLayoutProps}
        // drag={Platform.select({
        //   web: false,
        // })}
      >
        <Row sx={{ flexWrap: 'wrap', zIndex: -1 }}>
          <Presence>
            {value?.map((item, index) => {
              return (
                <MotiView
                  key={item}
                  from={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  layout={Platform.select({
                    native: Layout.springify().mass(0.5),
                    // .stiffness(500),
                  })}
                  exiting={Platform.select({
                    native: ZoomOut.duration(300),
                  })}
                >
                  <Press
                    sx={{ pr: 2, mb: 2 }}
                    onPress={() => {
                      remove(index)
                    }}
                  >
                    <Badge iconRight="close-outline" variant="muted3">
                      {item}
                    </Badge>
                  </Press>
                </MotiView>
              )
            })}
          </Presence>
        </Row>
      </ParentLayout>
    )
  }, [_renderItems, remove, value])

  return (
    <View>
      <ScrollToField name={name as string} />
      {!!label && labelPosition === 'top' && (
        <FieldLabel
          // isHovered={isHovered}
          hasErrored={hasErrored}
          isFocused={isFocused}
          required={required}
        >
          {label}
        </FieldLabel>
      )}

      <HoverTooltip
        textSx={{ bg: 'text', color: 'background' }}
        placement="top"
        anchor="right"
        text={tooltip}
        visible={isFocused}
      >
        <AutoComplete
          {...focusedBindings}
          {...autocompleteProps}
          sx={sx}
          options={options}
          keyExtractor={(item) => item.value}
          searching={searching}
          allowsCustomValue={allowsCustomValue}
          onSelect={onSelect}
          filter={filter}
          value={textValue}
          onChangeText={setTextValue}
          hasErrored={hasErrored}
          shouldSetControlledStateOnPress={false}
        >
          <AutoComplete.Empty allowsCustomValue>
            {emptyOptionText}
          </AutoComplete.Empty>
        </AutoComplete>
      </HoverTooltip>
      {!!error?.trim() && <ErrorText>{error}</ErrorText>}
      {value?.length > 0 && <Spacer height={2} />}
      {renderItems}
    </View>
  )
}
