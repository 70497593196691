import useTheme from '@beatgig/theme/use-theme'
import React, { useImperativeHandle } from 'react'
import { ThemeProvider, DefaultTheme } from 'react-native-paper'
import format from 'date-fns/format'
// import { DatePickerModal } from '@nandorojo/react-native-paper-dates'
import { DatePickerModal } from 'react-native-paper-dates'
import type {
  DatePickerModalRangeProps,
  DatePickerModalSingleProps,
  DatePickerModalExcludeInRangeProps,
  DatePickerModalMultiProps,
} from '@nandorojo/react-native-paper-dates'
import { View, Text } from 'dripsy'
import Press from '../press'
import { StyleSheet } from 'react-native'
import Ionicons from '@expo/vector-icons/build/Ionicons'

type PaperProps =
  | Omit<DatePickerModalRangeProps, 'visible' | 'onDismiss'>
  | Omit<DatePickerModalSingleProps, 'visible' | 'onDismiss'>
  | Omit<DatePickerModalExcludeInRangeProps, 'visible' | 'onDismiss'>
  | Omit<DatePickerModalMultiProps, 'visible' | 'onDismiss'>

export type ModalRef = {
  open?: () => void
}

type Props = PaperProps & {
  placeholder?: string
  error?: boolean
  onDismiss?: () => void
  visible?: boolean
  modalRef?: React.Ref<ModalRef>
}

function isMultiple(props: PaperProps): props is DatePickerModalMultiProps {
  return !!((props as DatePickerModalMultiProps)?.mode === 'multiple')
}

export default function PaperDatePicker(props: Props) {
  const {
    placeholder = 'Select Date',
    error = false,
    onConfirm,
    onDismiss,
    visible,
    modalRef,
  } = props

  const { colors } = useTheme()

  const [open, setOpen] = React.useState(false)

  useImperativeHandle(modalRef, () => ({
    open: () => {
      setOpen(true)
    },
  }))

  let previewDateText = ''

  let dates: Date[] = []

  if (isMultiple(props) && props.dates) {
    ({ dates } = props)
    dates = dates
      ?.filter(Boolean)
      .map((date) => (date instanceof Date ? date : new Date(date)))

    if (dates?.length) {
      const [firstDate, ...otherDates] = dates

      if (firstDate) {
        previewDateText = format(firstDate, 'MMM d, yyy')
      }
      if (previewDateText && otherDates.length) {
        previewDateText = `${previewDateText} (+${otherDates.length})`
      }
    }
  }

  const icon = 'calendar-outline'
  return (
    <Press onPress={() => setOpen(true)}>
      {({ hovered, focused }) => (
        <>
          <View
            sx={{
              px: 2,
              py: 2,
              borderWidth: 1,
              borderColor: error
                ? 'error'
                : hovered || focused
                ? 'primary'
                : 'muted5',
              borderRadius: 3,
              transitionProperty: 'border-color',
              transitionDuration: '250ms',
              bg: 'background',
            }}
          >
            <Text
              sx={{
                color: previewDateText ? 'text' : 'mutedText',
                py: 1,
                fontSize: 3,
              }}
            >
              {previewDateText || placeholder}
            </Text>
          </View>
          <ThemeProvider
            theme={{
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                ...colors,
                // text: colors.background,
                surface: colors.background,
              },
              dark: true,
            }}
          >
            <DatePickerModal
              {...(props as React.ComponentProps<typeof DatePickerModal>)}
              onConfirm={(...confirmation: unknown[]) => {
                setOpen(false)
                //   @ts-ignore
                onConfirm?.(...confirmation)
              }}
              onDismiss={() => {
                onDismiss?.()
                setOpen(false)
              }}
              visible={visible ?? open}
            />
          </ThemeProvider>
          {/* <DateTimePickerModal
        mode={mode}
        date={typeof date === 'string' ? new Date(date) : date}
        {...picker}
        isVisible={open}
        onCancel={(date) => {
          setOpen(false)
          onCancel(date)
        }}
        onConfirm={(date) => {
          onConfirm(date)
          setOpen(false)
        }}
        isDarkModeEnabled
      /> */}
          <View
            sx={{
              ...StyleSheet.absoluteFillObject,
              left: 'auto',
              justifyContent: 'center',
              pr: 3,
            }}
          >
            <Ionicons name={icon} color={colors?.text} size={25} />
          </View>
        </>
      )}
    </Press>
  )
}
