import React, { useMemo, useState } from 'react'
import { Formik, FormikConfig } from 'formik'
import WebModal from '@beatgig/components/web-modal'
import { IndoorsOrOutdoors } from '@beatgig/api-services'
import ErrorNote from '@beatgig/components/ErrorNote'
import { H3, Text } from 'dripsy'
import Spacer from '@beatgig/design/components/spacer'
import { APP_NAME } from '@beatgig/constants'
import * as yup from 'yup'
import Timezone from '@beatgig/forms/components/timezone'
import Subtitle from '@beatgig/forms/components/subtitle'
import locationToString from '@beatgig/helpers/location-to-string'
import HoverTooltip from '@beatgig/components/hover-tooltip'
import DateTimePickerContainer from '@beatgig/forms/fields/date-time-container/provider'
import DateTimeContextPickerField from '@beatgig/forms/fields/date-time-container/picker'
import Grid from '@beatgig/design/components/grid'
import ScrollablesProvider from '@beatgig/forms/context/scrollables/scrollables-provider'
import MultiDateTimeContainer from '@beatgig/forms/fields/multi-date-time-container/provider'
import MultiDateTimePicker from '@beatgig/forms/fields/multi-date-time-container/multi-date-picker'
import SubmitButton from '@beatgig/forms/fields/submit'
import FormRow from '@beatgig/forms/components/row'
import FormSet from '@beatgig/forms/components/set'
import TextField from '@beatgig/forms/fields/text-field'
import AutoCompleteMultiSelectFieldArray from '@beatgig/forms/fields/auto-complete-multi-select-field-array'
import PickerField from '@beatgig/forms/fields/picker-field'
import { Billing } from '@beatgig/api-services/booking'

import { CancelBookingRequest } from './cancel'

import {
  UpdateOrCreateBookingRequestProps,
  isCreateBookingRequestForm,
  CreateOrUpdateBookingRequestFormState as FormState,
  BookingRequestCreateForm,
} from './types'
import useRequestCloseForm from '@beatgig/forms/hooks/use-request-close-form'
import { DisableEditField } from './disable-edit-field'
import { UpdateBookingRequestFormBandConfigurations } from './band-configs'
import { BookingRequestPatch } from '@beatgig/gql'
import { useArtistSubgenresTypesense } from '@beatgig/search/lists/use-artist-subgenres'

type Props = UpdateOrCreateBookingRequestProps

type BookingRequestUpdate = BookingRequestPatch

const empty = {
  object: {},
}

const pickerOptions = {
  indoorsOrOutdoors: Object.values(IndoorsOrOutdoors),
  billing: Object.values(Billing),
}

export function UpdateBookingRequestForm(props: Props) {
  const {
    initialBookingRequest,
    onClose,
    formHeader = null,
    title = 'Edit Request',
    networkError,
    buttonTitle = 'Save',
    venueLocation,
    venueName,
    animated = false,
    onCancelBookingRequest,
    isCancelingBookingRequest,
    cancelBookingRequestError,
    useMultipleDates,
    canIEditPerformanceLength = true,
    canIEditStartTime = true,
  } = props

  const validRange = React.useMemo(
    () => ({
      startDate: new Date(),
    }),
    []
  )

  const { validationSchema } = useMemo<
    Pick<FormikConfig<FormState>, 'validationSchema'>
  >(
    () => ({
      validationSchema: yup.object().shape<FormState>({
        startTime: !useMultipleDates
          ? (yup
              .date()
              .nullable()
              .min(validRange.startDate, ' ')
              .required(' ') as any)
          : undefined,
        startTimes: useMultipleDates
          ? (yup
              .array(
                yup
                  .date()
                  .nullable()
                  .min(validRange.startDate, 'Please pick a future date.')
                  .required('Date required.')
              )
              .required('Dates required.')
              .min(1, 'Please enter at least one date.') as any)
          : undefined,
        performanceLengthMins: yup
          .number()
          .typeError('Please enter a number.')
          .typeError(' ')
          .min(1, ' ')
          .nullable()
          .required(' '),
        // @ts-expect-error it's ok
        budget: yup
          .object({
            high: yup
              .number()
              .min(1, ' ')
              .typeError(' ')
              .nullable()
              .required(' '),
          })
          .nullable(),
        // @ts-expect-error
        genres: yup
          .array()
          .optional()
          .of(yup.string().optional().nullable())
          .nullable(),
        // @ts-expect-error why does this fail?
        bandConfigurationOptions: yup
          .array()
          .of(yup.string())
          .nullable()
          .optional(),
        // @ts-expect-error whatevsies
        artistInstructions: yup.string().optional().nullable(),
        // @ts-expect-error whatevsies
        adminInstructions: yup.string().optional().nullable(),
      }),
    }),
    [useMultipleDates, validRange.startDate]
  )

  const [subgenreSearchText, setSubgenreSearchText] = useState('')
  const subgenresQuery = useArtistSubgenresTypesense(subgenreSearchText)

  const subgenres = subgenresQuery.data?.facet_counts?.[0]?.counts.map(
    ({ value }) => value
  )

  const { requestClose } = useRequestCloseForm()

  return (
    <ScrollablesProvider>
      {(scrollableProps) => (
        <Formik<FormState>
          onSubmit={async (bookingRequest, formik) => {
            if (
              !props.useMultipleDates &&
              !isCreateBookingRequestForm(bookingRequest)
            ) {
              const { didWork } = await props.onUpdate(
                bookingRequest,
                formik,
                scrollableProps
              )
              if (didWork) {
                onClose()
              }
            } else if (
              isCreateBookingRequestForm(bookingRequest) &&
              props.useMultipleDates
            ) {
              const { didWork } = await props.onCreate(
                bookingRequest,
                formik,
                scrollableProps
              )
              if (didWork) {
                onClose()
              }
            }
            return false
          }}
          initialValues={initialBookingRequest}
          validationSchema={validationSchema}
        >
          {({ dirty }) => (
            <WebModal
              animated={animated}
              onRequestClose={requestClose(dirty, onClose)}
              header
              title={title}
              button={
                <SubmitButton networkError={!!networkError} formName={title}>
                  {buttonTitle}
                </SubmitButton>
              }
            >
              {formHeader}
              <ErrorNote error={networkError} sx={{ my: 3 }} />
              <FormRow>
                <H3>Time</H3>
                {props.useMultipleDates && (
                  <MultiDateTimeContainer<BookingRequestCreateForm>
                    timezone={venueLocation.timezone}
                    name="startTimes"
                  >
                    <MultiDateTimePicker
                      timeLabel="Start Time"
                      datesLabel="Event Dates"
                      validRange={validRange}
                      timezone={venueLocation.timezone}
                    />
                  </MultiDateTimeContainer>
                )}
                {!props.useMultipleDates && (
                  <DateTimePickerContainer<BookingRequestUpdate>
                    name="startTime"
                    timezone={venueLocation.timezone as string}
                  >
                    <Grid columns={[1, 2]}>
                      <Grid.Item>
                        <DateTimeContextPickerField<BookingRequestUpdate>
                          name="startTime"
                          label="Event Date"
                          mode="date"
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <DateTimeContextPickerField<BookingRequestUpdate>
                          name="startTime"
                          label="Start Time"
                          mode="time"
                        />
                      </Grid.Item>
                    </Grid>
                    <Timezone
                      sx={{ mt: 3 }}
                      timezoneName={venueLocation.timezone as string}
                    />
                  </DateTimePickerContainer>
                )}
                <DisableEditField
                  isFieldEditable={canIEditStartTime}
                  fieldNameEnglish="Start time"
                />
              </FormRow>

              <FormRow>
                <HoverTooltip
                  text={`We'll recommend artists to perform at ${venueName}.`}
                  placement="top"
                  anchor="right"
                  bg="text"
                >
                  <Text>
                    <Text sx={{ fontWeight: 'bold' }}>{venueName} </Text>
                    <Text sx={{ color: 'mutedText' }}>
                      {locationToString(venueLocation)}
                    </Text>
                  </Text>
                </HoverTooltip>
              </FormRow>

              <FormRow>
                <H3>Performance</H3>
                <FormSet>
                  <TextField<FormState>
                    name="performanceLengthMins"
                    label="Performance length (mins)"
                    mask="number"
                    required
                    editable={canIEditPerformanceLength}
                    placeholder="Enter minutes"
                    tooltip={
                      canIEditPerformanceLength
                        ? 'How long will this performance be?'
                        : "This isn't editable, since there are active bids."
                    }
                  />
                  <PickerField<FormState>
                    name="billing"
                    label="Billing"
                    items={pickerOptions.billing}
                    placeholder={empty.object}
                  />
                </FormSet>
                <Spacer height={3} />
                <PickerField<FormState>
                  name="indoorsOrOutdoors"
                  label="Performance location"
                  items={pickerOptions.indoorsOrOutdoors}
                  placeholder={empty.object}
                  required
                />
              </FormRow>

              <FormRow sx={{ zIndex: 2 }}>
                <H3>Budget</H3>
                <FormSet>
                  <TextField<FormState>
                    name="budget.low"
                    mask="money"
                    label="Low"
                    tooltip="What's your minimum budget?"
                  />
                  <TextField<FormState>
                    name="budget.high"
                    mask="money"
                    label="High"
                    tooltip="What's your max spend for this event?"
                    required
                  />
                </FormSet>
                <Spacer height={2} />
                <Subtitle>
                  Tell us your approximate budget for this event. {`We'll`} use
                  it to tailor your recommended artists.
                </Subtitle>
              </FormRow>

              <FormRow sx={{ zIndex: 2 }}>
                <H3>About</H3>
                <AutoCompleteMultiSelectFieldArray<FormState>
                  name="genres"
                  label="Genres"
                  options={subgenres}
                  searching={subgenresQuery.isValidating}
                  placeholder={`Search genres...`}
                  emptyOptionText="Custom genre"
                  tooltip="Which genres should we recommend?"
                  required
                  filter={false}
                  value={subgenreSearchText}
                  onChangeText={setSubgenreSearchText}
                />
                <Spacer height={2} />
                <Subtitle sx={{ zIndex: -1 }}>
                  Enter genres you would like to consider for this booking. This
                  helps us optimize the artists we recommend.
                </Subtitle>
              </FormRow>

              <UpdateBookingRequestFormBandConfigurations />

              <FormRow>
                <H3>{APP_NAME} Support Instructions</H3>
                <TextField<FormState>
                  name="adminInstructions"
                  tooltip="Help us perfect recommendations."
                  // label="High"
                  required
                  multiline
                  numberOfLines={3}
                  placeholder={`Anything else your support represenative should know about your event? Are you looking for anything in particular?`}
                />
                <Subtitle sx={{ pb: 0, pt: 3 }}>
                  This text will only be seen by your {APP_NAME} support
                  representative. It will not be visible to artists.
                </Subtitle>
              </FormRow>
              <FormRow>
                <H3>Artist Instructions</H3>
                <TextField<FormState>
                  name="artistInstructions"
                  tooltip="Information for the artist."
                  required
                  multiline
                  numberOfLines={3}
                  placeholder="Anything the artist should know about this performance?"
                />
                <Subtitle sx={{ pb: 0, pt: 3 }}>
                  This text is visible to artists. It gives them an idea of what{' '}
                  {`you're`} looking for.
                </Subtitle>
              </FormRow>
              {!!onCancelBookingRequest && (
                <>
                  <Spacer height={4} />
                  <CancelBookingRequest
                    onCancelBookingRequest={onCancelBookingRequest}
                    isCancelingBookingRequest={isCancelingBookingRequest}
                    cancelBookingRequestError={cancelBookingRequestError}
                  />
                </>
              )}
            </WebModal>
          )}
        </Formik>
      )}
    </ScrollablesProvider>
  )
}
