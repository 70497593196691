import useFormatAlgoliaFilters from '../../hooks/use-format-algolia-filters'
import { useState, useMemo } from 'react'
import { DripsyTheme } from '@beatgig/theme'
import { usePersistState } from '@beatgig/hooks/use-persist-state'
import useStable from '@beatgig/design/hooks/use-stable'

type State = 'on' | 'off'

type Props = {
  defaults?: { [key in State]?: boolean }
  variants?: { [key in State]?: keyof DripsyTheme['colors'] }
  labels: {
    [key in State]: string
  }
  titles: {
    [key in State]?: string
  } & {
    default: string
  }
  description?: string
  persistKey: string | null
}

export type BooleanGraphqlFilter = boolean | undefined

export default function useBooleanFilter<Obj extends object = {}>(
  filterPath: (string & {}) | keyof Obj,
  { defaults, variants, labels, titles, description, persistKey }: Props
) {
  const [state, setState] = useState(() => {
    return {
      on: {
        selected: defaults?.on ?? true,
        label: labels.on,
        variant: variants?.on,
        title: titles?.on ?? labels.on,
      },
      off: {
        selected: defaults?.off ?? true,
        label: labels.off,
        variant: variants?.off,
        title: titles?.off ?? labels.off,
      },
    }
  })

  const [booleanState, setBooleanState] = usePersistState(persistKey, {
    state,
    setState,
  })

  const filters: string[] = []

  if (booleanState.on.selected) {
    filters.push('true')
  }
  if (booleanState.off.selected) {
    filters.push('false')
  }

  // if the filters have all (or no) booleans selected, it's undefined
  let graphqlFilter: boolean | undefined
  if (filters.length === 1) {
    graphqlFilter = filters[0] === 'true'
  }

  const stateRef = useStable(booleanState)

  const handlers = useMemo(() => {
    const toggleItem = (value: State) => {
      setBooleanState({
        ...stateRef.current,
        [value]: {
          ...stateRef.current[value],
          selected: !stateRef.current[value].selected,
        },
      })
    }

    return {
      toggleItem,
      makeToggleItem: (value: State) => () => toggleItem(value),
    }
  }, [setBooleanState, stateRef])

  const { facetFilters } = useFormatAlgoliaFilters({
    facetFilters: {
      [filterPath]: {
        condition: 'OR' as const,
        filters,
      },
    },
  })

  return {
    booleanState,
    facetFilters,
    handlers,
    selectedCount: Object.values(booleanState).filter((value) => value.selected)
      .length,
    filterPath,
    title: titles?.default,
    description,
    graphqlFilter,
  }
}
