import React from 'react'
import { ServerError, Location } from '@beatgig/api-services'

import { ScrollableFormikOnSubmitProp } from '@beatgig/forms/context/scrollables/scrollables-context'

import {
  BookingRequestInput,
  BookingRequestPatch,
  CreateBookingRequestsBuyerMutationVariables,
} from '@beatgig/gql'

export type BookingRequestCreateForm = BookingRequestInput &
  Pick<CreateBookingRequestsBuyerMutationVariables, 'startTimes'>

type BookingRequestUpdate = BookingRequestPatch

export type CreateOrUpdateBookingRequestFormState =
  | BookingRequestUpdate
  | BookingRequestCreateForm

export function isCreateBookingRequestForm(
  form: CreateOrUpdateBookingRequestFormState
): form is BookingRequestCreateForm {
  return (
    !!(form as BookingRequestCreateForm)?.startTimes &&
    !(form as BookingRequestUpdate)?.startTime
  )
}

type SubmissionResult = {
  didWork: boolean
}

export type CreateBookingRequestProps = {
  onCreate: ScrollableFormikOnSubmitProp<
    BookingRequestCreateForm,
    SubmissionResult
  >
  initialBookingRequest: BookingRequestCreateForm
  useMultipleDates: true
  canIEditStartTime?: never
  canIEditPerformanceLength?: never
}

export type UpdateBookingRequestProps = {
  onUpdate: ScrollableFormikOnSubmitProp<BookingRequestUpdate, SubmissionResult>
  initialBookingRequest: BookingRequestPatch
  useMultipleDates: false
  canIEditStartTime: boolean
  canIEditPerformanceLength: boolean
}

export type UpdateOrCreateBookingRequestSharedProps = {
  onClose: () => void
  formHeader?: React.ReactNode | null
  title?: string
  networkError?: ServerError | null
  venueLocation: Location
  venueName: string
  buttonTitle?: string
  animated?: boolean
  onCancelBookingRequest?: () => Promise<void>
  isCancelingBookingRequest?: boolean
  cancelBookingRequestError?: ServerError
}

export type UpdateOrCreateBookingRequestProps =
  UpdateOrCreateBookingRequestSharedProps &
    (UpdateBookingRequestProps | CreateBookingRequestProps)
