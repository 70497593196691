import { useFieldFast } from '@beatgig/forms/hooks/use-fast-field'
import React from 'react'
import { CreateOrUpdateBookingRequestFormState } from './types'
import { useFormikContext } from 'formik'
import { BandConfigurationOption } from '@beatgig/gql'
import Badge from '@beatgig/design/components/badge'
import useDisplayBandConfiguration from '@beatgig/search/hooks/use-display-band-configuration'
import { View, H3, Text } from 'dripsy'
import Press from '@beatgig/components/press'
import ErrorText from '@beatgig/forms/fields/error'
import FormRow from '@beatgig/forms/components/row'
import Subtitle from '@beatgig/forms/components/subtitle'
import { ScrollToField } from '@beatgig/forms/fields/scroll-to-field'

export function UpdateBookingRequestFormBandConfigurations() {
  const name: keyof CreateOrUpdateBookingRequestFormState =
    'bandConfigurationOptions'
  const [{ value }, { error }, { setValue }] = useFieldFast<
    CreateOrUpdateBookingRequestFormState['bandConfigurationOptions'],
    CreateOrUpdateBookingRequestFormState
  >(name)
  const { submitCount } =
    useFormikContext<CreateOrUpdateBookingRequestFormState>()
  const { displayBandConfiguration } = useDisplayBandConfiguration()

  const add = (bandConfig: BandConfigurationOption) => {
    let next = value || []
    next = [...next]
    if (!next.includes(bandConfig)) {
      next.push(bandConfig)
    }
    setValue(next, true)
  }

  const remove = (bandConfig: BandConfigurationOption) => {
    const next = value?.filter((config) => config !== bandConfig)
    setValue(next, true)
  }

  const makeToggleSelected = (bandConfig: BandConfigurationOption) => () => {
    if (value?.includes(bandConfig)) {
      remove(bandConfig)
    } else {
      add(bandConfig)
    }
  }

  const allConfigurations = [
    // FIXME gql should this be hard coded? and untyped??
    BandConfigurationOption.SOLO,
    BandConfigurationOption.DUO,
    BandConfigurationOption.TRIO,
    BandConfigurationOption.FOUR_PLUS,
  ]

  return (
    <>
      <ScrollToField name={name as string} />
      <FormRow sx={{ zIndex: -2 }}>
        <H3>Band Configurations</H3>
        <Subtitle>
          If you have a preference for band configurations, select them here.
        </Subtitle>
        <View sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          {allConfigurations.map((option) => {
            const selected = value?.includes(option)

            return (
              <Press onPress={makeToggleSelected(option)} key={option}>
                <Badge
                  variant={selected ? 'primary' : 'text'}
                  filling={selected ? 'filled' : 'outlined'}
                  sx={{ mr: 3, my: 2 }}
                  textSx={{ textTransform: 'capitalize' }}
                >
                  {displayBandConfiguration(option)}
                </Badge>
              </Press>
            )
          })}
        </View>
        {!!value?.length && (
          <Text sx={{ mt: 3 }}>
            {value.length === allConfigurations.length
              ? 'All configurations'
              : `${value.length} ${
                  value.length > 1 ? 'configurations' : 'configuration'
                }`}{' '}
            selected.
          </Text>
        )}
        {!!error?.trim() && !!submitCount && (
          <ErrorText sx={{ mt: 3 }}>{error}</ErrorText>
        )}
      </FormRow>
    </>
  )
}
