import React from 'react'
import type { ServerError } from '@beatgig/api-services'
import ErrorNote from '@beatgig/components/ErrorNote'
import { View } from 'dripsy'
import Button from '@beatgig/components/button'
import { useFormikContext } from 'formik'

type Props = {
  cancelBookingRequestError?: ServerError
  isCancelingBookingRequest?: boolean
  onCancelBookingRequest: () => Promise<void>
}

export function CancelBookingRequest(props: Props) {
  const {
    cancelBookingRequestError,
    onCancelBookingRequest,
    isCancelingBookingRequest,
  } = props

  const { setSubmitting } = useFormikContext()
  const onPress = async () => {
    try {
      setSubmitting(true)
      await onCancelBookingRequest()
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
    }
  }

  return (
    <>
      <ErrorNote
        label="Cancelation error"
        error={cancelBookingRequestError}
        sx={{ mb: 3 }}
      />
      <View sx={{ alignItems: 'center' }}>
        <Button
          sx={{
            bg: 'error',
            borderColor: 'error',
            color: 'text',
          }}
          onPress={onPress}
          loading={isCancelingBookingRequest}
        >
          Cancel
        </Button>
      </View>
    </>
  )
}
