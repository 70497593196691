import useStable from '@beatgig/design/hooks/use-stable'
import React, { useState, useMemo, useEffect } from 'react'
import { useFieldArray } from '../../hooks/use-field-array'
import initialFormDateToDateTime from '../date-time-container/initial-form-date-to-datetime'
import {
  DateObject,
  DateTimeObject,
  MultiDateTimeContainerContext,
  TimeObject,
} from './context'
import { DateTime } from 'luxon'
import { ScrollToField } from '../scroll-to-field'

type Props<FormState> = {
  name: keyof FormState | (string & {})
  /**
   * Path to the timezone name in the form, such as `venue_location.timezone`
   */
  timezone: string
  children: React.ReactNode
}

const dateObjectToDateTime = (datetime: DateTimeObject, timezone: string) => {
  return DateTime.fromObject(datetime).setZone(timezone, {
    // IMPORTANT
    // this lets us give raw time values, turn it into another zone, but preserving the raw values
    keepLocalTime: true,
  })
}

/**
 * Similar to DateTimecontainer, but with an array of dates.
 *
 * Must have at least one date!
 */
export default function MultiDateTimeContainer<FormState>(
  props: Props<FormState>
) {
  const { name, timezone, children } = props

  const [
    { value: formDates = [] },
    { error },
    { setValue: setFormDates, remove },
  ] = useFieldArray<Date | string>(name)

  const [dateObjects, setDateObjects] = useState(() => {
    return formDates.map((date) => {
      const { year, month, day } = initialFormDateToDateTime(
        date,
        timezone,
        name as string
      )

      return { year, month, day }
    })
  })

  const [timeObject, setTimeObject] = useState(() => {
    const date = formDates?.[0]
    if (date) {
      const { hour, minute, second } = initialFormDateToDateTime(
        date,
        timezone,
        name as string
      )

      return { hour, minute, second }
    }

    return {
      hour: 19,
      minute: 0,
      second: 0,
    }
  })

  const stableSetFormDates = useStable(setFormDates)
  const stableFormDates = useStable(formDates)

  useEffect(
    function maybeUpdateFormWhenTimezoneTimeOrDatesChange() {
      const setFormDates = stableSetFormDates.current

      const currentFormDates =
        stableFormDates?.current.map((dateOrString) => {
          if (dateOrString instanceof Date) {
            return dateOrString
          }
          return new Date(dateOrString)
        }) ?? []

      const currentFormDatesMillis = currentFormDates
        .map((date) => {
          return DateTime.fromJSDate(date).toMillis()
        })
        .sort()

      const nextFormDateTimeObjects = dateObjects.map((dateObject) => {
        return dateObjectToDateTime({ ...dateObject, ...timeObject }, timezone)
      })

      const nextFormDatesMillis = nextFormDateTimeObjects
        .map((dateTimeObject) => dateTimeObject.toMillis())
        .sort()

      const areMillisArraysEqual = <T extends number>(a: T[], b: T[]) =>
        a.length === b.length && a.every((v, i) => v === b[i])

      const haveDatesChanged = !areMillisArraysEqual(
        nextFormDatesMillis,
        currentFormDatesMillis
      )

      const shouldUpdateFormState =
        haveDatesChanged && nextFormDateTimeObjects.length

      if (shouldUpdateFormState) {
        console.log('🐼 [multi-date-time-picker] will update!', {
          haveDatesChanged,
          shouldUpdateFormState,
          dates: nextFormDateTimeObjects.length,
        })

        setFormDates(
          nextFormDateTimeObjects.map((dateTimeObject) =>
            dateTimeObject.toJSDate()
          )
        )
      } else {
        console.log('🦉 [multi-date-time-picker] will NOT update!', {
          haveDatesChanged,
          shouldUpdateFormState,
          dates: nextFormDateTimeObjects.length,
        })
      }
    },
    [stableSetFormDates, timezone, dateObjects, timeObject, stableFormDates]
  )

  const context: MultiDateTimeContainerContext = useMemo(
    () => ({
      dateObjects,
      onChangeDates: ({ dates }: { dates: DateObject[] | null }) => {
        setDateObjects((currentDateTimeObjects) => {
          if (!dates?.length) {
            // force a re-render, but we need at least one date
            return [currentDateTimeObjects[0]].filter(Boolean)
          }
          return dates
        })
      },
      timeObject,
      onChangeTime: (time: TimeObject) => {
        setTimeObject(time)
      },
      onRemoveDate: (index) => {
        // remove(index) oops, never mutate the form here.
        setDateObjects((dateObjects) => {
          const nextDateObjects = [...dateObjects]

          nextDateObjects.splice(index, 1)

          return nextDateObjects
        })
      },
      name: name as string,
    }),
    [dateObjects, name, timeObject]
  )

  return (
    <MultiDateTimeContainerContext.Provider value={context}>
      <ScrollToField name={name as string} />
      {children}
    </MultiDateTimeContainerContext.Provider>
  )
}
