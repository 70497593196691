import { createContext, useContext } from 'react'
import { Sentry } from '@beatgig/helpers/sentry'

export type DateObject = {
  day: number
  month: number
  year: number
}

export type TimeObject = {
  minute: number
  hour: number
  second: number
}

export type DateTimeObject = TimeObject & DateObject

export type MultiDateTimeContainerContext = {
  dateObjects: DateObject[]
  onChangeTime: (date: TimeObject) => void
  onChangeDates: (info: { dates: DateObject[] }) => void
  timeObject: TimeObject
  onRemoveDate: (index: number, date: DateObject) => void
  name: string
}

export const MultiDateTimeContainerContext = createContext<MultiDateTimeContainerContext>(
  {
    dateObjects: [],
    onChangeDates: () => {
      console.error(
        '[MultiDateTimeContainerContext] onChangeDates missing context'
      )
      Sentry.captureException(
        '[MultiDateTimeContainerContext] onChangeDates missing context'
      )
    },
    onChangeTime: () => {
      console.error(
        '[MultiDateTimeContainerContext] onChangeTime missing context'
      )
      Sentry.captureException(
        '[MultiDateTimeContainerContext] onChangeTime missing context'
      )
    },
    onRemoveDate: () => {
      console.error(
        '[MultiDateTimeContainerContext] onRemoveDate missing context'
      )
      Sentry.captureException(
        '[MultiDateTimeContainerContext] onRemoveDate missing context'
      )
    },
    timeObject: {
      hour: 20,
      minute: 0,
      second: 0,
    },
    name: 'start_times',
  }
)

export const useMultiDateTimeFieldContext = () =>
  useContext(MultiDateTimeContainerContext)
