import React from 'react'
import { View, Text } from 'dripsy'
import Card from '@beatgig/design/components/card'
import { Formik, FormikConfig } from 'formik'
import Subtitle from './subtitle'
import FormTextField from '../fields/text-field'
import SubmitButton from '../fields/submit'
import HoverTooltip from '@beatgig/components/hover-tooltip'

type Props<FormState extends object, Result> = {
  onCancel?: () => void
  emptyTooltip?: string
  buttonColor?: Pick<
    React.ComponentProps<typeof SubmitButton>,
    'color'
  >['color']
  initialValues: FormState
  submitText?: string
  onSubmit: (
    ...params: Parameters<FormikConfig<FormState>['onSubmit']>
  ) => Promise<{ shouldReset: boolean }>
  result: NonNullable<Result> | null
  canIEdit: boolean
  /**
   * Name of the text field in the form state.
   */
  name: keyof FormState
  /**
   * Example: "What should customers know? Any drink specials? Prices or discounts?"
   */
  placeholder: string
  /**
   * Example:
   * ```js
   * const placeholder = `Use this text field to describe your event to your customers. It
              will appear on{' '}
              {booking.venue_name ? `${booking.venue_name}'s` : 'your'}{' '}
              {APP_NAME} profile &amp; public calendar when it is published.`
    ```
   */
  subtitle?: string
}

export default function TextFieldForm<
  FormState extends object,
  Result = FormState
>({
  initialValues,
  onSubmit,
  result,
  canIEdit,
  name,
  placeholder,
  subtitle,
  buttonColor,
  onCancel,
  emptyTooltip = 'Nothing new to save.',
  submitText = 'Save',
}: Props<FormState, Result>) {
  return (
    <Formik<FormState>
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        const { resetForm } = formik

        const { shouldReset } = await onSubmit(values, formik)

        if (shouldReset) {
          resetForm({
            values,
          })
        }
        return null
      }}
    >
      {({ dirty, isSubmitting }) => (
        <Card
          sx={{
            borderColor: dirty ? 'callout' : result ? 'success' : 'border',
          }}
        >
          <Card.Content>
            <FormTextField<FormState>
              name={name}
              multiline
              //   label="Public Description"
              numberOfLines={4}
              editable={canIEdit && !isSubmitting}
              placeholder={placeholder}
            />
            {!!subtitle && <Subtitle sx={{ mt: 3 }}>{subtitle}</Subtitle>}
          </Card.Content>

          {canIEdit && (
            <Card.Footer sx={{ bg: 'muted', justifyContent: 'space-between' }}>
              {dirty ? (
                <Text sx={{ color: 'callout' }}>Unsaved changes.</Text>
              ) : !!result ? (
                <Text sx={{ color: 'success' }}>Changes saved.</Text>
              ) : (
                <View />
              )}
              <HoverTooltip
                bg="text"
                placement="top"
                anchor="right"
                text={
                  result && !dirty
                    ? 'Changes saved.'
                    : dirty
                    ? undefined
                    : emptyTooltip
                }
              >
                <SubmitButton
                  color={buttonColor}
                  formName="Booking Public Event Description"
                  disabled={!dirty}
                >
                  {submitText}
                </SubmitButton>
              </HoverTooltip>
            </Card.Footer>
          )}
        </Card>
      )}
    </Formik>
  )
}
